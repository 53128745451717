<template>
	<bbh-sub-header>
		<template #subHeaderTitle>
			<template v-if="localRouteIsHome"> Hallo {{ userAnrede }} </template>
			<template v-if="localRouteIsEditor">
				<doc-name />
			</template>
			<template v-if="localRouteIsKollektionen"> Vertragswelt </template>
			<template v-if="localRouteIsMeineDokumente"> Meine Dokumente </template>
			<template v-if="localRouteIsFragebogen">
				BBH-Muster individualisieren
			</template>
			<template v-if="localRouteIsKollektionenBearbeiten">
				Kollektionen bearbeiten
			</template>
			<template v-if="localRouteIsVerwaltungsansichten">
				Verwaltungsansichten
			</template>
			<template v-if="localRouteIsHashtags"> Hashtags </template>
			<template v-if="localRouteIsEntscheidungen"> Entscheidungen </template>
			<template v-if="localRouteIsBenutzereinstellungen">
				Benutzereinstellungen
			</template>
			<template v-if="localRouteIsVertragsDetails"> Vertrags-Details </template>
			<template v-if="localRouteIsVertragBearbeiten">
				Vertrag bearbeiten
			</template>
			<template v-if="localRouteIsMustervertraege"> Musterverträge </template>
			<template v-if="localRouteIsMusterklauseln"> Musterklauseln </template>
			<template v-if="localRouteIsMusterdokumente"> Musterdokumente </template>
			<template v-if="localRouteIsTaskansicht"> Taskansicht </template>
			<template v-if="localRouteIsFragebogenBearbeiten">
				Fragebogen bearbeiten
			</template>
			<template v-if="localRouteIsNeuerMandant"> Neuer Mandant </template>
			<template v-if="localRouteIsPaketverwaltung"> Paketverwaltung </template>
			<template v-if="localRouteIsRechnungen"> Rechnungen </template>
			<template v-if="localRouteIsMandantenUsersList">
				Liste aller Nutzer aller Mandanten
			</template>
			<template v-if="localRouteIsSecretView"> Secret View </template>
			<template v-if="localRouteMeineRechnungen"> Meine Rechnungen </template>
			<template v-if="localRouteMeineAbos"> Meine Abos </template>
			<template v-if="localRouteIsServicezugang"> Servicezugänge </template>
		</template>
		<template v-if="localRouteIsHome" #subHeaderSubTitle>
			{{ subheaderText }}
		</template>
		<template #subHeaderActionBar>
			<div class="d-flex gap-10">
				<bbh-action-button
					v-for="(btn, index) in subHeaderActionBar"
					:id="btn.id"
					:key="index"
					:icon="btn.icon"
					:text="btn.text"
					:disabled="btn.disabled"
					:readonly="btn.readonly"
					:tooltip="btn.tooltip"
					:show-tooltip="btn.tooltip && btn.tooltip.length > 0"
					:loading="subHeaderLoading"
					:btn-class="btn.class"
					:class="{ 'bbh-btn-disabled': btn.disabled }"
					@click="handleBtnClick(btn.text, btn.value)"
				/>
			</div>
		</template>
	</bbh-sub-header>
</template>

<script>
	import { mapGetters, mapMutations, mapState } from "vuex";
	import { v4 as uuid } from "uuid";
	import SubHeaderEvents from "@src/model/enums/SubHeaderEvents";
	import types from "../../store/types";
	import DocName from "../editor/DocName.vue";
	import { ref } from "vue";
	import {
		aktuelleRoute,
		routeIsHome,
		routeIsEditor,
		routeIsKollektionen,
		routeIsMeineDokumente,
		routeIsFragebogen,
		routeIsVerwaltungsansichten,
		routeIsHashtags,
		routeIsEntscheidungen,
		routeIsBenutzereinstellungen,
		routeIsKollektionenBearbeiten,
		routeIsVertragsDetails,
		routeIsVertragBearbeiten,
		routeIsMustervertraege,
		routeIsMusterklauseln,
		routeIsMusterdokumente,
		routeIsTaskansicht,
		routeIsFragebogenBearbeiten,
		routeIsNeuerMandant,
		routeIsPaketverwaltung,
		routeIsMandantenUsersList,
		routeIsSecretView,
		routeIsRechnungen,
		routeIsMeineAbos,
		routeIsMeineRechnungen,
		routeIsServicezugang,
	} from "../../utils/routerFunctions";

	const userGetters = types.getters.user;

	const subHeaderGetters = types.getters.subHeader;
	const subHeaderMutations = types.mutations.subHeader;

	const viewContentMutations = types.mutations.viewContent;

	export default {
		name: "SubHeader",
		components: { DocName },
		data: () => ({
			secretCounter: 0,
			subheaderText: "",
		}),
		computed: {
			...mapGetters({
				userAnrede: userGetters.USER_ANREDE,
				subHeaderActionBar: subHeaderGetters.SUB_HEADER_ACTION_BAR,
			}),

			...mapState({
				subHeaderLoading: (state) => state.subHeader.subHeaderLoading,
			}),

			localRouteIsHome() {
				return routeIsHome(this.$route);
			},
			localRouteIsEditor() {
				return routeIsEditor(this.$route);
			},
			localRouteIsKollektionen() {
				return routeIsKollektionen(this.$route);
			},
			localRouteIsMeineDokumente() {
				return routeIsMeineDokumente(this.$route);
			},
			localRouteIsFragebogen() {
				return routeIsFragebogen(this.$route);
			},
			localRouteIsVerwaltungsansichten() {
				return routeIsVerwaltungsansichten(this.$route);
			},
			localRouteIsHashtags() {
				return routeIsHashtags(this.$route);
			},
			localRouteIsEntscheidungen() {
				return routeIsEntscheidungen(this.$route);
			},
			localRouteIsBenutzereinstellungen() {
				return routeIsBenutzereinstellungen(this.$route);
			},
			localRouteIsKollektionenBearbeiten() {
				return routeIsKollektionenBearbeiten(this.$route);
			},
			localRouteIsVertragsDetails() {
				return routeIsVertragsDetails(this.$route);
			},
			localRouteIsVertragBearbeiten() {
				return routeIsVertragBearbeiten(this.$route);
			},
			localRouteIsMustervertraege() {
				return routeIsMustervertraege(this.$route);
			},
			localRouteIsMusterklauseln() {
				return routeIsMusterklauseln(this.$route);
			},
			localRouteIsMusterdokumente() {
				return routeIsMusterdokumente(this.$route);
			},
			localRouteIsTaskansicht() {
				return routeIsTaskansicht(this.$route);
			},
			localRouteIsFragebogenBearbeiten() {
				return routeIsFragebogenBearbeiten(this.$route);
			},
			localRouteIsNeuerMandant() {
				return routeIsNeuerMandant(this.$route);
			},
			localRouteIsPaketverwaltung() {
				return routeIsPaketverwaltung(this.$route);
			},
			localRouteIsRechnungen() {
				return routeIsRechnungen(this.$route);
			},
			localRouteIsMandantenUsersList() {
				return routeIsMandantenUsersList(this.$route);
			},
			localRouteIsSecretView() {
				return routeIsSecretView(this.$route);
			},
			localRouteIsServicezugang() {
				return routeIsServicezugang(this.$route);
			},
			localAktuelleRoute() {
				return aktuelleRoute(this.$route);
			},
			localRouteMeineRechnungen() {
				return routeIsMeineRechnungen(this.$route);
			},
			localRouteMeineAbos() {
				return routeIsMeineAbos(this.$route);
			},
		},
		methods: {
			startCountdown() {
				const releaseDate = new Date("2025-03-03T12:00:00");

				const updateCountdown = () => {
					const now = new Date();
					let diff = releaseDate - now;
          
					if (diff <= 0) {
						this.subheaderText =
							"Der große Release der Layoutfunktion ist da! 🎉";
						clearInterval(interval); // Countdown stoppen
						return;
					}

					const days = Math.floor(diff / (1000 * 60 * 60 * 24));
					const timeString = new Date(diff).toISOString().substring(11, 19); // hh:mm:ss

					this.subheaderText = `Noch ${String(days).padStart(
						2,
						"0"
					)}:${timeString} bis zum großen Release der Layoutfunktion.`;
				};
				const interval = setInterval(updateCountdown, 1000);
				updateCountdown(); // Direkt einmal aufrufen, damit es nicht erst nach 1 Sekunde startet
			},

			...mapMutations({
				neuesDokument: subHeaderMutations.NEUES_DOKUMENT,
				neuesHashtag: subHeaderMutations.NEUES_HASHTAG,
				neueEntscheidung: subHeaderMutations.NEUE_ENTSCHEIDUNG,
				vertragBearbeiten: subHeaderMutations.VERTRAG_BEARBEITEN,
				kollektionen: subHeaderMutations.KOLLEKTIONEN,
				approveContract: subHeaderMutations.APPROVE_CONTRACT,
				neueAufgabe: subHeaderMutations.NEUE_AUFGABE,
				setNewMusterelement: subHeaderMutations.SET_NEW_MUSTERELEMENT,
				editContract: subHeaderMutations.EDIT_CONTRACT,
				toggleMigration: subHeaderMutations.SET_MIGRATION,
				toggleIndividuellerVertrag:
					subHeaderMutations.SET_INDIVIDUELLER_VERTRAG,
				setFragebogenBearbeiten: subHeaderMutations.SET_FRAGEBOGEN_BEARBEITEN,
				paketverwaltung: subHeaderMutations.PAKETVERWALTUNG,
				setFragebogen: subHeaderMutations.SET_FRAGEBOGEN,
				setServicezugangDialog: subHeaderMutations.SET_SERVICEZUGANG_DIALOG,

				setKollektionenBearbeiten:
					subHeaderMutations.SET_KOLLEKTIONEN_BEARBEITEN,

				increaseResetFragebogenCounter:
					viewContentMutations.INCREASE_RESET_FRAGEBOGEN_COUNTER,
			}),

			handleBtnClick(text, value) {
				switch (text) {
					case "neues Dokument":
						this.neuesDokument();
						break;
					case "neues Musterdokument":
						this.setNewMusterelement(true);
						break;
					case "neue Musterklausel":
						this.setNewMusterelement(true);
						break;
					case "neuer Mustervertrag":
						this.setNewMusterelement(true);
						break;
					case "neues Hashtag":
						this.neuesHashtag();
						break;
					case "neue Entscheidung":
						this.neueEntscheidung();
						break;
					case "Ansprechpartner":
						this.vertragBearbeiten("Ansprechpartner");
						break;
					case "Kollektionen bearbeiten":
						this.$router.push({ name: "KollektionenBearbeiten" });
						break;
					case "Bearbeitung beenden":
						if (this.localRouteIsKollektionenBearbeiten) {
							this.$router.push({ name: "Kollektionen" });
						} else if (this.localRouteIsVertragBearbeiten) {
							this.$router.push({ name: "VertragsDetails" });
						}
						break;
					case "Vertrag bearbeiten":
						this.editContract(1);
						break;
					case "Vertrag freigeben":
						this.approveContract();
						break;
					case "Auswählen für Freigabe":
						this.setKollektionenBearbeiten(
							SubHeaderEvents.kollektionenBearbeiten.SELECT_FOR_APPROVAL
						);
						break;
					case "Freigeben":
						this.setKollektionenBearbeiten(
							SubHeaderEvents.kollektionenBearbeiten.APPROVE_COLLECTIONS
						);
						break;
					case "Abbrechen":
						this.setKollektionenBearbeiten(
							SubHeaderEvents.kollektionenBearbeiten.CANCEL_APPROVAL
						);
						break;
					case "Fragebogen zurücksetzen":
						this.increaseResetFragebogenCounter();
						break;
					case "neue Aufgabe":
						this.neueAufgabe();
						break;
					case "Migration":
						this.toggleMigration(true);
						break;
					case "individueller Vertrag":
						this.toggleIndividuellerVertrag(true);
						break;
					case "Fragebogen kopieren":
						this.setFragebogenBearbeiten({
							type: "copy",
							value: uuid(),
						});
						break;
					case "Fragebogen einfügen":
						this.setFragebogenBearbeiten({
							type: "paste",
							value: uuid(),
						});
						break;
					case "Antworten kopieren":
						this.setFragebogen({
							type: "copy",
							value: uuid(),
						});
						break;
					case "Antworten einfügen":
						this.setFragebogen({
							type: "paste",
							value: uuid(),
						});
						break;
					case "Zugang hinzufügen":
						this.setServicezugangDialog(true);
						break;
					case "Paketverwaltung":
						this.$router.push({
							name: "Paketverwaltung",
							params: { id: value },
						});
						break;
					case "Individuelle Preise":
						this.vertragBearbeiten("IndividuellePreise");
						break;
					case "Rabatt":
						this.kollektionen("Rabatt");
						break;
					case "Paket hinzufügen":
						this.paketverwaltung("paketHinzufuegen");
						break;
					default:
						break;
				}
			},

			startSecretCounter() {
				if (this.secretCounter === 0) {
					this.secretCounter = 1;
					setTimeout(() => {
						this.secretCounter = 0;
					}, 1000);
				} else {
					this.secretCounter += 1;
				}
			},

			routeToSecretView() {
				if (this.secretCounter > 0) {
					this.$router.push({ name: "SecretView" });
				}
			},
		},
		mounted() {
			this.startCountdown();
		},
	};
</script>

<style lang="scss"></style>
